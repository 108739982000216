import React, { useState, useEffect } from 'react';
import "user/courseDetail/component/CategoryBar.css";

function CategoryBar({ onScrollToImage, scrollToProject, content, isProjectPublic }) {
    const [activeCategory, setActiveCategory] = useState(0); // 현재 활성화된 카테고리

    useEffect(() => {
        const handleScroll = () => {
            const imageElements = document.querySelectorAll('.content-image-container');
            const projectSection = document.querySelector('.project-section');
            const stickyHeader = document.querySelector('.sticky-header');
            
            const headerHeight = stickyHeader?.offsetHeight || 0;
            const scrollPosition = window.scrollY + headerHeight;

            // 프로젝트 섹션 체크
            if (projectSection && isProjectPublic) {
                const projectTop = projectSection.offsetTop;
                const projectBottom = projectTop + projectSection.offsetHeight;

                // 스크롤이 프로젝트 섹션 안에 있을 때
                if (scrollPosition >= projectTop - headerHeight && scrollPosition < projectBottom) {
                    setActiveCategory('project');  // 프로젝트 카테고리 활성화
                    return;
                }
            }

            // 현재 스크롤 위치에 해당하는 섹션 찾기
            let currentSectionIndex = -1;
            for (let i = 0; i < imageElements.length; i++) {
                const element = imageElements[i];
                const elementTop = element.offsetTop;
                const elementBottom = elementTop + element.offsetHeight;

                if (scrollPosition >= elementTop && scrollPosition < elementBottom) {
                    currentSectionIndex = i;
                    break;
                }
            }

            // 프로젝트 섹션을 벗어난 후 일반 카테고리 활성화
            if (currentSectionIndex !== -1) {
                if (content[currentSectionIndex].courseContentName) {
                    setActiveCategory(currentSectionIndex); // 일반 카테고리 활성화
                } else {
                    let prevCategoryIndex = currentSectionIndex - 1;
                    while (prevCategoryIndex >= 0 && !content[prevCategoryIndex].courseContentName) {
                        prevCategoryIndex--;
                    }
                    setActiveCategory(prevCategoryIndex); // 이전 카테고리 활성화
                }
            }
        };

        // 스크롤 이벤트 설정
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // 초기 실행
        
        return () => window.removeEventListener('scroll', handleScroll);
    }, [content, isProjectPublic]);

    return (
        <nav className='category-bar'>
            <ul>
                {/* 프로젝트 카테고리 */}
                {isProjectPublic && (
                    <li 
                        onClick={scrollToProject}
                        role="button"
                        tabIndex={0}
                        className={activeCategory === 'project' ? 'active' : ''} // activeCategory가 'project'일 때 활성화
                    >
                        프로젝트
                    </li>
                )}
                {/* 카테고리 목록 */}
                {content.map((course, index) => (
                    course.courseContentName ? (
                        // 일반 카테고리
                        <li 
                            key={index} 
                            onClick={() => onScrollToImage(index)}
                            role="button"
                            tabIndex={0}
                            className={activeCategory === index ? 'active' : ''} // activeCategory가 index일 때 활성화
                        >
                            {course.courseContentName}
                        </li>
                    ) : (
                        // 숨김 카테고리
                        <li key={index} className="hidden-category" aria-hidden="true" />
                    )
                ))}
            </ul>
        </nav>
    );
}

export default CategoryBar;
