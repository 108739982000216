import AdminPage from "admin/AdminPage";
import DashBoard from "admin/dashBoard/DashBoard";
import AddEmpPracticeTraining from "admin/employTraining/AddEmpPracticeTraining";
import AddEmpTraining from "admin/employTraining/AddEmpTraining";
import EmployTrainingList from "admin/employTraining/EmployTrainingList";
import EmpTrainingPractice from "admin/employTraining/EmpTrainingPractice";
import EmpVolunteer from "admin/employTraining/EmpVolunteer";
import EmpVolunteerList from "admin/employTraining/EmpVolunteerList";
import ModifyEmpPracticeTraining from "admin/employTraining/ModifyEmpPracticeTraining";
import ModifyEmpTraining from "admin/employTraining/ModifyEmpTraining";
import AddJobTraining from "admin/jobTraining/AddJobTraining";
import AddPracticeTraining from "admin/jobTraining/AddPracticeTraining";
import JobTrainingList from "admin/jobTraining/JobTrainingList";
import JobTrainingPractice from "admin/jobTraining/JobTrainingPractice";
import ModifyJobTraining from "admin/jobTraining/ModifyJobTraining";
import ModifyPracticeTraining from "admin/jobTraining/ModifyPracticeTraining";
import Volunteer from "admin/jobTraining/Volunteer";
import VolunteerList from "admin/jobTraining/VolunteerList";
import Login from "admin/Login";
import CategoryDetail from "admin/setting/CategoryDetail";
import CategoryList from "admin/setting/CategoryList";
import ChangeInfo from "admin/setting/ChangeInfo";
import ChangePW from "admin/setting/ChangePW";
import ModifyBanner from "admin/setting/ModifyBanner";
import Firstmap from "admin/siteContents/Firstmap";
import Firstzone from "admin/siteContents/Firstzone";
import Footer from "admin/siteContents/Footer";
import MainPage from "admin/siteContents/MainPage";
import AddBlog from "admin/story/AddBlog";
import AddStory from "admin/story/AddStory";
import AddVideoStory from "admin/story/AddVideoStory";
import BlogList from "admin/story/BlogList";
import ModifyBlog from "admin/story/ModifyBlog";
import ModifyStory from "admin/story/ModifyStory";
import ModifyVideoStory from "admin/story/ModifyVideoStory";
import StoryList from "admin/story/StoryList";
import VideoStoryList from "admin/story/VideoStoryList";
import AddFaq from "admin/withFirstzone/AddFaq";
import AddNotice from "admin/withFirstzone/AddNotice";
import FaqList from "admin/withFirstzone/FaqList";
import ModifyFaq from "admin/withFirstzone/ModifyFaq";
import ModifyNotice from "admin/withFirstzone/ModifyNotice";
import NoticeList from "admin/withFirstzone/NoticeList";
import QnaAnswer from "admin/withFirstzone/QnaAnswer";
import QnaList from "admin/withFirstzone/QnaList";
import PrivateRoutes from "PrivateRouter";
import { Route, Routes } from "react-router-dom";
import Main from "user/main/Main";
import "./App.css";

import CoursePage from 'user/course/CoursePage';
import CourseDetail from "user/courseDetail/CourseDetail";
import Success from 'user/register/component/Success';
import InputInfo from 'user/register/employTraining/InputInfo';
import InputResume from 'user/register/jobCareer/InputResume';
import RegisterPage from 'user/register/RegisterPage';

import ErrorPage404 from "user/commons/ErrorPage404";
import Content from "user/story/commons/StoryContentsDetail";
import Story from "user/story/Story";
import WithFirstzone from "user/withfirstzone/First";
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <>
    <ScrollToTop/>
    <Routes>
      
      <Route path="adminlogin" element={<Login />} />
      
      <Route element={<PrivateRoutes />}>
        <Route path="admin/*" element={<AdminPage />}>

          <Route index element={<DashBoard />} />
            <Route element={<PrivateRoutes />}>
            <Route path="sitecontent/mainpage" element={<MainPage />} />
            <Route path="sitecontent/firstzone" element={<Firstzone />} />
            <Route path="sitecontent/firstmap" element={<Firstmap />} />
            <Route path="sitecontent/footer" element={<Footer />} />
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route path="employtraining/employlist" element={<EmployTrainingList />} />
            <Route path="employtraining/addemptraining" element={<AddEmpTraining />} />
            <Route path="employtraining/emppracticelist" element={<EmpTrainingPractice />} />
            <Route path="employtraining/modifyemptraining/:courseId" element={<ModifyEmpTraining />} />
            <Route path="employtraining/modifyemppractice/:sessionId" element={<ModifyEmpPracticeTraining />} />
            <Route path="employtraining/addemppractice" element={<AddEmpPracticeTraining />} />
            <Route path="employtraining/empvolunteerlist" element={<EmpVolunteerList />} />
            <Route path="employtraining/empvolunteer/:sessionId" element={<EmpVolunteer />} />
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route path="jobtraining/traininglist" element={<JobTrainingList />} />
            <Route path="jobtraining/practicelist" element={<JobTrainingPractice />} />
            <Route path="jobtraining/addjobtraining" element={<AddJobTraining />} />
            <Route path="jobtraining/modifyjobtraining/:courseId" element={<ModifyJobTraining />} />
            <Route path="jobtraining/addjobpractice" element={<AddPracticeTraining />} />
            <Route path="jobtraining/modifypractice/:sessionId" element={<ModifyPracticeTraining />} />
            <Route path="jobtraining/volunteerlist" element={<VolunteerList />} />
            <Route path="jobtraining/volunteer/:sessionId" element={<Volunteer />} />
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route path="story/eyetechlist" element={<StoryList />} />
            <Route path="story/addeyetech" element={<AddStory />} />
            <Route path="story/modifyeyetech/:eyetechId" element={<ModifyStory />} />
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route path="story/videostorylist" element={<VideoStoryList />} />
            <Route path="story/modifyvideostory/:videoStoryId" element={<ModifyVideoStory />} />
            <Route path="story/addvideostory" element={< AddVideoStory />} />
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route path="story/bloglist" element={<BlogList />} />
            <Route path="story/modifyblog/:blogId" element={<ModifyBlog />} />
            <Route path="story/addblog" element={< AddBlog />} />
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route path="withfirstzone/noticelist" element={< NoticeList />} />
            <Route path="withfirstzone/modifynotice/:noticeId" element={< ModifyNotice />} />
            <Route path="withfirstzone/addnotice" element={< AddNotice />} />
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route path="withfirstzone/faqlist" element={< FaqList />} />
            <Route path="withfirstzone/modifyfaq/:faqId" element={< ModifyFaq />} />
            <Route path="withfirstzone/addfaq" element={< AddFaq />} />
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route path="withfirstzone/qnalist" element={< QnaList />} />
            <Route path="withfirstzone/qnaanswer/:qnaId" element={< QnaAnswer />} />
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route path="setting/categorylist" element={< CategoryList />} />
            <Route path="setting/categorydetail/:categoryId" element={< CategoryDetail />} />
            <Route path="setting/modifybanner" element={< ModifyBanner />} />
            <Route path="setting/changepw" element={< ChangePW />} />
            <Route path="setting/changeinfo" element={<ChangeInfo />} />
          </Route>
        </Route>
      </Route>


      {/* 메인 페이지 */}
      <Route path="/" element={<Main />} />

      {/* 과정 관련 페이지 */}
      <Route path="/:courseType/course" element={<CoursePage />} />
      <Route path="/:courseType/detail/:sessionId" element={<CourseDetail />} />
      
      {/* 회원가입 페이지 설정 및 중첩 라우팅 */}
      <Route path="/:courseType/registration" element={<RegisterPage />}>
        <Route path="inputinfo/:sessionId" element={<InputInfo />} />
        <Route path="inputresume/:sessionId" element={<InputResume />} />
        <Route path="success/:sessionId" element={<Success />} />
      </Route>


      <Route path="/story" element={<Story />}></Route>
      <Route path="/content/:id" element={<Content />}></Route>

      <Route path='/support' element={<WithFirstzone></WithFirstzone>}></Route>

      <Route path="*" element={<ErrorPage404 />} />
    </Routes>
    </>
  );
}

export default App;
