import Sidebar from "admin/Sidebar";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

function AdminPage(props) {
  useEffect(() => {
    document.title = '관리자 | 신한DS 금융SW 아카데미';
  }, []);

  return (
    <div className="app">
      <Sidebar />
      <div className="content-box">
        <Outlet />
      </div>
    </div>
  );
}

export default AdminPage;
