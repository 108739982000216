import React from "react";
import "user/main/EduFacilityComponent.css";
import FacilitySwiperComponent from "./swiper/FacilitySwiperComponent";

function EduFacilityComponent({
  facilityImages,
  facilityText,
  facilityBackgroundImage,
}) {
  return (
    <div
      className="facility-zone"
      style={{
        backgroundImage: `url(/uploads/${facilityBackgroundImage})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {/* <div className="facility-header">
        <div className="facility-title">
          <img src={facility} alt="로고" className="main-facility" />
          교육시설 소개
        </div>
      </div>
      <div className="mobile-facility-header">
        <div className="mobile-facility-title">교육시설 소개</div>
      </div> */}
      <div className="facility-text">꿈을 이루어줄 신한 금융SW 아카데미</div>
      <div className="facility-subtext">{facilityText}</div>
      <FacilitySwiperComponent facilityImages={facilityImages} />
    </div>
  );
}

export default EduFacilityComponent;
