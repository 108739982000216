import React, { useState, useEffect } from "react";
import "admin/dashBoard/DashBoard.css";
import visitor from "images/visitor.png";
import "react-datepicker/dist/react-datepicker.css";
import calendar from "images/calendar.png";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import { type } from "@testing-library/user-event/dist/type";
import plugin from "chartjs-plugin-datalabels";
import { call } from "service/ApiService";

// Chart.js 컴포넌트 등록
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

function DashBoard(props) {
  const today = new Date();
  const tenDaysAgo = new Date();
  const maxRangeDays = 15;
  tenDaysAgo.setDate(today.getDate() - maxRangeDays);

  const [startDate, setStartDate] = useState(tenDaysAgo);
  const [endDate, setEndDate] = useState(today);
  const [dataForChart, setDataForChart] = useState({});
  const [courses, setCourses] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [roundData, setRoundData] = useState(
    {
      labels: [],
      datasets: [
        {
          label: "신청자수",
          data: [],
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            // "rgba(153, 102, 255, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            // "rgba(153, 102, 255, 1)",
          ],
          borderWidth: 1,
        },
      ],
    }
  );

  // 랜덤 데이터 생성 함수 삭제요망
  const generateRandomData = (start, days) => {
    const data = {};
    for (let i = 0; i < days; i++) {
      const currentDate = new Date(start);
      currentDate.setDate(start.getDate() + i);
      const dateStr = currentDate.toISOString().split("T")[0]; // "YYYY-MM-DD" 형식
      data[dateStr] = Math.floor(Math.random() * 1000);
    }
    return data;
  };

  function transformData(response) {
    return response.reduce((acc, { date, uniqueVisitors }) => {
      acc[date] = uniqueVisitors;
      return acc;
    }, {});
  }

  const getVisitorData = async (start, days) => {
    const formatDate = (date) => date.toISOString().split("T")[0]; // 날짜를 "YYYY-MM-DD" 형식으로 변환

    const formattedStartDate = formatDate(start);
    const endDate = new Date(start); // 시작일을 기준으로 새로운 날짜 객체 생성
    endDate.setDate(start.getDate() + days); // 시작일로부터 days 후의 날짜를 계산
    const formattedEndDate = formatDate(endDate);

    try {
      const response = await call(`/api/v1/admin/visitor-statistics?startDate=${formattedStartDate}&endDate=${formattedEndDate}`, "GET");
      const transformedData = transformData(response);
      return transformedData;
    } catch (error) {
      alert(error.message);
    }
  };

  useEffect(() => {
    const fetchVisitorData = async () => {
      const transformedData = await getVisitorData(startDate, maxRangeDays + 1);
      if (transformedData) { // transformedData가 유효한지 확인
        setDataForChart(transformedData);
      }
    };

    fetchVisitorData();
  }, [startDate]);

  const getTrainingSessions = (type) => {
    call(`/api/v1/admin/training-session/boardlist${type === undefined ? '' : `?courseType=${type}`}`, "GET")
      .then((response) => {
        const mappedData = response.map((session) => ({
          id: session.sessionId,
          과정분류: session.courseType.includes("취업") ? "취업트레이닝" : "직무훈련",
          일자: new Date(session.createDate).toLocaleDateString('ko-KR'),
          과정명: session.sessionName,
          신청자수: session.totalApplicant,
        }));
        setCourses(mappedData);
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const getTrainingSessionsToday = () => {
    call("/api/v1/admin/training-session/boardlist/today", "GET")
      .then((response) => {
        // Mapping the response to update roundData
        const labels = response.map(session => session.sessionName);
        const data = response.map(session => session.applicant);

        setRoundData(prevData => ({
          ...prevData,
          labels, // Update labels with the session names
          datasets: [
            {
              ...prevData.datasets[0],
              data, // Update data with the applicant counts
            },
          ],
        }));

        console.log(response); // Log the response if needed
      })
      .catch((error) => {
        alert(error.message);
      });
  };


  useEffect(() => {
    getTrainingSessions();
    getTrainingSessionsToday();
  }, []);

  // 날짜만 비교하는 함수
  const isDateInRange = (date, start, end) => {
    const dateToCompare = new Date(date).setHours(0, 0, 0, 0); // 시간 정보 제거
    const startToCompare = new Date(start).setHours(0, 0, 0, 0); // 시간 정보 제거
    const endToCompare = end
      ? new Date(end).setHours(0, 0, 0, 0)
      : startToCompare; // 시간 정보 제거

    return startToCompare <= dateToCompare && dateToCompare <= endToCompare;
  };

  // 선택한 날짜 범위에 맞게 데이터를 필터링하여 차트에 표시
  const filteredData = Object.keys(dataForChart)
    .filter((date) => isDateInRange(date, startDate, endDate)) // 시간 정보 없이 날짜만 비교
    .map((date) => ({
      date,
      value: dataForChart[date],
    }));

  // 막대차트 데이터 형식
  const chartData = {
    labels: filteredData.map((d) => d.date),
    datasets: [
      {
        label: "접속자 수 (단위: 명)",
        data: filteredData.map((d) => d.value),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  // 막대차트
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },  
    },
  };

  const roundOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "left",
        padding: 100,
        labels: {
          boxHeight: 20
        },
      },
    },
  };

  // 선택된 카테고리에 따라 실행되는 함수
  const handleCategoryChange = (event) => {
    const value = event.target.value;
    setSelectedCategory(value);

    if (value === '취업트레이닝과정') {
      getTrainingSessions("취업트레이닝과정");
    } else if (value === '직무훈련과정') {
      getTrainingSessions("직무훈련과정");
    } else {
      getTrainingSessions();
    }
  };

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName-dashboard">DashBoard</div>
        <div className="visitor-chart">
          <div className="dash-visitor-header">
            <div className="dash-visitor">
              <img src={visitor} alt="icon" />
              <div className="dash-title">일일 접속자 수</div>
            </div>
            <div className="calendar-zone">
              <label>
                <img src={calendar} alt="캘린더" className="calendar-icon" />
              </label>
              <DatePicker
                className="datePicker"
                selectsRange
                startDate={startDate}
                endDate={endDate}
                onChange={(dates) => {
                  const [start, end] = dates;

                  // startDate 선택 시 15일 후로 endDate 자동 설정
                  if (start) {
                    const newEndDate = new Date(start);
                    newEndDate.setDate(start.getDate() + 14); // startDate로부터 15일 뒤의 날짜 설정
                    setStartDate(start);
                    setEndDate(end || newEndDate); // endDate가 선택되지 않은 경우 자동 설정
                  } else {
                    setStartDate(null);
                    setEndDate(null);
                  }

                  // 새로운 범위에 대한 데이터 생성
                  setDataForChart(generateRandomData(start, maxRangeDays + 1));
                }}
                dateFormat="yyyy/MM/dd"
                selectsEnd
              />
            </div>
          </div>
          <div className="chart-zone">
            <Bar data={chartData} options={options} className="chart" />
          </div>
        </div>
        <div className="app-list">
          <div className="comul-app">
            <div className="dash-visitor-header">
              <div className="dash-visitor">
                <img src={visitor} alt="icon" />
                <div className="dash-title">개설 과정 누적 신청자 수</div>
              </div>
            </div>
            <div className="prop-cnt">
              <table className="prop-table">
                <thead>
                  <tr>
                    <th className="dash-table">과정분류</th>
                    <th className="dash-date">일자</th>
                    <th>과정명</th>
                    <th className="dash-table">신청자수</th>
                  </tr>
                </thead>
                <tbody>
                  {courses.map((course) => (
                    <tr key={course.id}>
                      <td>{course.과정분류}</td>
                      <td>{course.일자}</td>
                      <td className="dash-class">{course.과정명}</td>
                      <td>{course.신청자수}명</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="today-app">
            <div className="dash-header">
              <img src={visitor} alt="icon" />
              <div className="dash-title">오늘 신청자 수</div>
            </div>
            <div className="pie-graph">
              <Doughnut data={roundData} options={roundOptions} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashBoard;
