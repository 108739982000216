import React, { useEffect } from 'react';
import "user/commons/DetailMenu.css";

function DetailMenu({ selectedMenu, setselectedMenu, visibleMenus, setCategoryName }) {

    const DetailMenuClick = (menu, name) => {
        setselectedMenu(menu);
        setCategoryName(name.replace(/\s+/g, ''));
        // console.log("Selected Category Name:", name.replace(/\s+/g, ''));
    };

    // 메뉴가 변경될 때마다 타이틀 업데이트
    useEffect(() => {
        const menuTitles = {
            itech: "아이테크",
            videostory: "영상 스토리",
            blog: "블로그",
            firstzone: "신한 금융SW 아카데미",
            firstmap: "교육장 위치",
            notice: "공지사항",
            faq: "자주 묻는 질문",
        };

        if (selectedMenu && menuTitles[selectedMenu]) {
            document.title = `${menuTitles[selectedMenu]} | 신한DS 금융SW 아카데미`;
        }
    }, [selectedMenu]);

    return (
        <div className='detailmenu-container'>
            <div className='detailmenu-list-container'>
                {visibleMenus.includes('itech') && (
                    <span 
                        className={selectedMenu === 'itech' ? 'active' : ''} 
                        onClick={() => DetailMenuClick('itech', '아이테크')}
                    >
                        아이테크
                    </span>
                )}
                {visibleMenus.includes('videostory') && (
                    <span 
                        className={selectedMenu === 'videostory' ? 'active' : ''} 
                        onClick={() => DetailMenuClick('videostory', '영상 스토리')}
                    >
                        영상 스토리
                    </span>
                )}
                {visibleMenus.includes('blog') && (
                    <span 
                        className={selectedMenu === 'blog' ? 'active' : ''} 
                        onClick={() => DetailMenuClick('blog', '블로그')}
                    >
                        블로그
                    </span>
                )}
                {visibleMenus.includes('firstzone') && (
                    <span 
                        className={selectedMenu === 'firstzone' ? 'active' : ''} 
                        onClick={() => DetailMenuClick('firstzone', '신한 금융SW 아카데미')}
                    >
                        신한 금융SW 아카데미
                    </span>
                )}
                {visibleMenus.includes('firstmap') && (
                    <span 
                        className={selectedMenu === 'firstmap' ? 'active' : ''} 
                        onClick={() => DetailMenuClick('firstmap', '교육장 위치')}
                    >
                        교육장 위치
                    </span>
                )}
                {visibleMenus.includes('notice') && (
                    <span 
                        className={selectedMenu === 'notice' ? 'active' : ''} 
                        onClick={() => DetailMenuClick('notice', '공지사항')}
                    >
                        공지사항
                    </span>
                )}
                {visibleMenus.includes('faq') && (
                    <span 
                        className={selectedMenu === 'faq' ? 'active' : ''} 
                        onClick={() => DetailMenuClick('faq', '자주 묻는 질문')}
                    >
                        자주 묻는 질문
                    </span>
                )}
            </div>
        </div>
    );
}

export default DetailMenu;
