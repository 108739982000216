import { useEffect, useState, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { call } from "service/ApiService";
import Loading from "user/commons/Loading";
import PageLayout from "user/PageLayout";
import CategorySection from "user/courseDetail/component/CategorySection";
import "user/courseDetail/CourseDetail.css";
import ErrorPageApi from "user/commons/ErrorPageApi";

const CONSTANTS = {
    API: {
        URL: '/api/v1/training-session',
    },
    INITIAL_STATE: {
        content: [], // 과정 컨텐츠 배열
        outline: { 
            course: {
                contents: [], // 과정 세부 컨텐츠
                projects: [], // 과정 프로젝트 목록
                courseIsProjectPublic: false, // 프로젝트 공개 여부
            }
        }, 
        project: [], // 프로젝트 정보
        isProjectPublic: false, // 프로젝트 공개 여부
    }
};

function CourseDetail() {
    const [state, setState] = useState({
        courseData: CONSTANTS.INITIAL_STATE, // 과정 데이터
        isLoading: true, // 로딩 상태
        isError: false  // 에러 상태
    }); 

    // URL 파라미터에서 과정 타입과 세션 ID 추출
    const { courseType, sessionId } = useParams();

     // API 응답 데이터 유효성 검사 함수
    const validateResponse = useCallback((response) => {
        if (!response || !response?.course || !Array.isArray(response.course.contents)) {
            throw new Error();
        }

        // 유효한 데이터 형식으로 변환하여 반환
        return {
            content: response.course.contents ?? [],
            outline: response,
            project: response.course.projects ?? [],
            isProjectPublic: Boolean(response.course.courseIsProjectPublic),
        };
    }, []);

    // 과정 데이터를 가져오는 API 호출 함수
    const fetchCourseData = useCallback(async () => {
        try {
             // 로딩 상태 시작
            setState(prevState => ({ 
                ...prevState, 
                isLoading: true,
                isError: false
            }));

             // API 호출
            const response = await call(
                `${CONSTANTS.API.URL}/${sessionId}`, 
                'GET',
                null,
                'application/json'
            );

            // 응답 데이터 검증 및 상태 업데이트
            const validatedData = validateResponse(response);
            setState(prevState => ({ 
                ...prevState, 
                courseData: validatedData, 
                isLoading: false 
            }));
        } catch (error) {
            // 에러 처리
            setState(prevState => ({ 
                ...prevState, 
                isError: true,
                isLoading: false
            }));
        }
    }, [sessionId, validateResponse]);

    useEffect(() => {
        if (sessionId) {
            fetchCourseData();
        }
    }, [fetchCourseData, sessionId]);

    // CategorySection 컴포넌트에 전달할 props 메모이제이션
    const sectionProps = useMemo(() => ({
        categoryProps: {
            content: state.courseData.content,
            outline: state.courseData.outline,
            project: state.courseData.project,
            isProjectPublic: state.courseData.isProjectPublic,
            courseType,
        }
    }), [state.courseData, courseType]);

    const { isLoading, isError, courseData } = state;

     // 페이지 타이틀 설정
     useEffect(() => {
        if (state.courseData.outline?.sessionName) {
            document.title = `${state.courseData.outline.sessionName} | 신한DS 금융SW 아카데미`;
        } else {
            // 데이터 로딩 전이나 에러 시 기본 타이틀
            document.title = "과정 상세 | 신한DS 금융SW 아카데미";
        }
    }, [state.courseData.outline?.sessionName]);

    // useEffect 추가
    useEffect(() => {
        if (isLoading) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isLoading]);

    // isError를 설정하는 핸들러 함수 추가
    const handleSetError = useCallback((error) => {
        setState(prevState => ({
            ...prevState,
            isError: error
        }));
    }, []);

    return (
        <PageLayout isCourseDetailPage setIsError={handleSetError}>
            {isLoading ? (
                // 로딩 중일 때 표시
                <div 
                    className="loading-overlay" 
                    role="progressbar"
                    aria-label="과정 상세 정보를 불러오는 중입니다"
                >
                    <Loading />
                </div>
            ) : isError ? (
                // 에러 발생 시 표시
                <ErrorPageApi aria-label="에러 발생"/>
            ) : courseData.outline && (
                // 데이터 로드 완료 시 표시
                <main className="course-detail-container">
                    <div className="detail-content">
                        <CategorySection {...sectionProps.categoryProps} />
                    </div>
                </main>
            )}
        </PageLayout>
    );
}

export default CourseDetail;
