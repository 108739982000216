import { useEffect, useRef, useState, useCallback, memo } from 'react';
import CategoryBar from 'user/courseDetail/component/CategoryBar';
import "user/courseDetail/component/CategorySection.css";
import defaultImg from "images/defaultImg.png"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import RegisterSection from './RegisterSection';
import 'swiper/css/navigation';

// 상수 정의
const CONSTANTS = {
    // 반응형 디자인을 위한 브레이크포인트
    BREAKPOINTS: {
        MOBILE: 768, // 모바일 기준점
        TABLET: 1023 // 태블릿 기준점
    },
    SCROLL: {
        THRESHOLD: 50,
        DEBOUNCE_DELAY: 150
    },
    // Swiper 슬라이더 설정
    SWIPER: {
        modules: [Navigation, Pagination],
        navigation: {
            enabled: true,
            prevEl: '.custom-prev-button',
            nextEl: '.custom-next-button'
        },
        pagination: { clickable: true },
        spaceBetween: 30,  // 슬라이드 간격
        slidesPerView: 1,  // 한 번에 하나의 슬라이드만 표시
        loop: true  // 무한 루프 활성화
    }
};

// 이벤트 디바운싱을 위한 유틸리티 함수
// fn: 실행할 함수, delay: 지연 시간
const debounce = (fn, delay) => {
    let timeoutId;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn(...args), delay);
    };
};

// 이미지 컴포넌트
const ContentImage = memo(({ src, alt, refIndex, className, imageRefs, onError }) => (
    <div className="image-wrapper">
        <img
            src={`/uploads/${src}`} // 이미지 소스 경로
            alt={alt} // 이미지 대체 텍스트
            ref={el => imageRefs.current[refIndex] = el} // 이미지 참조 설정
            onError={onError} // 이미지 로드 실패 시 처리
            className={className} // 이미지 클래스 이름
            loading="lazy" // 이미지 지연 로드 활성화
        />
    </div>
));

// 메인 CategorySection 컴포넌트
const CategorySection = memo(({ outline, content, project, isProjectPublic, courseType }) => {
    const imageRefs = useRef([]);  // 이미지 요소들의 참조 배열
    const projectSectionRef = useRef(null);  // 프로젝트 섹션 참조

    // 상태 관리
    const [showTopButton, setShowTopButton] = useState(false);
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    // 특정 요소로 스크롤하는 함수
    const scrollToElement = useCallback((element) => {
        if (!element) return;
        
        const stickyHeader = document.querySelector('.sticky-header');
        const headerHeight = stickyHeader ? stickyHeader.offsetHeight : 0;
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;

        window.scrollTo({
            top: elementPosition - headerHeight,
            behavior: 'smooth'
        });
    }, []);

    // 특정 이미지로 스크롤하는 함수
    const scrollToImage = useCallback((index) => {
        const refIndex = window.innerWidth <= CONSTANTS.BREAKPOINTS.TABLET ? 
            index * 2 + 1 : index * 2;
        scrollToElement(imageRefs.current[refIndex]);
    }, [scrollToElement]);

    // 프로젝트 섹션으로 스크롤하는 함수
    const scrollToProject = useCallback(() => {
        scrollToElement(projectSectionRef.current);
    }, [scrollToElement]);

    // 페이지 최상단으로 스크롤하는 함수
    const scrollToTop = useCallback(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    // 이미지 로드 실패 시 처리하는 함수
    const handleImgError = useCallback((e) => {
        if (e.target.src !== defaultImg) {
            e.target.src = defaultImg;
            e.target.onerror = null;
        }
    }, []);

     // 파일 다운로드 처리 함수
    const handleDownload = useCallback(async (fileUrl) => {
        if (!fileUrl) {
            alert('다운로드할 파일이 없습니다.');
            return;
        }

        try {
            // 파일 다운로드 요청
            const response = await fetch(`/uploads/${fileUrl}`);
            if (!response.ok) throw new Error('파일 다운로드 실패');

            // Blob 형태로 파일 받기
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

             // 다운로드 링크 생성 및 클릭
            const link = document.createElement('a');
            link.href = url;
            link.download = outline.courseFile || '커리큘럼.pdf';
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            alert('파일 다운로드에 실패했습니다.');
        }
    }, [outline.courseFile]);

    // 프로젝트 링크 핸들러
    const handleProjectClick = useCallback((url) => {
        if (!url) {
            return;
        }
        window.open(url, '_blank', 'noopener noreferrer');
    }, []);

    // 윈도우 이벤트 리스너 설정
    useEffect(() => {
        // 스크롤 위치 감지만 남김
        const handleScroll = debounce(() => {
            setShowTopButton(window.scrollY > CONSTANTS.SCROLL.THRESHOLD);
        }, CONSTANTS.SCROLL.DEBOUNCE_DELAY);

        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

   // 컨텐츠 렌더링 함수
    const renderContent = useCallback(() => (
        content.map((item, index) => (
            <div key={`content-${index}`}>
                <div className="content-image-container">
                    {/* 웹용 이미지 */}
                    <ContentImage
                        src={item.courseContentImage1}
                        alt={`웹용 컨텐츠 이미지 ${index + 1}`}
                        refIndex={index * 2}
                        className="webImg"
                        imageRefs={imageRefs}
                        onError={handleImgError}
                    />

                    {/* 모바일용 이미지 */}
                    <ContentImage
                        src={item.courseContentImage2}
                        alt={`모바일용 컨텐츠 이미지 ${index + 1}`}
                        refIndex={index * 2 + 1}
                        className="mobileImg"
                        imageRefs={imageRefs}
                        onError={handleImgError}
                    />
                </div>
                {item.courseContentName === "커리큘럼" && outline.courseFile && (
                    <div className="curriculum-container">
                        <button 
                            className="curriculum-download-btn"
                            onClick={() => handleDownload(outline.courseFile)}
                        >
                            세부 커리큘럼 다운로드 받기
                        </button>
                    </div>
                )}
            </div>
        ))
    ), [content, handleDownload, outline.courseFile, handleImgError]);

    const renderProjects = useCallback(() => (
        <>
        <div className="swiper-container-wrapper">
            <Swiper 
                {...CONSTANTS.SWIPER} 
                className="category-section-project"
                onSlideChange={(swiper) => setActiveSlideIndex(swiper.realIndex)}
            >
                {project.map((item, index) => (
                    <SwiperSlide key={`project-${index}`}>
                        <div className="project-slide-container">
                            {/* 프로젝트 웹 이미지 */}
                            <img 
                                src={`/uploads/${item.courseProjectWebImage}`}
                                alt={`프로젝트 웹 이미지 ${index + 1}`}
                                onError={handleImgError}
                                loading="lazy"
                                className="webImg"
                            />
                            {/* 프로젝트 모바일 이미지 */}
                            <img 
                                src={`/uploads/${item.courseProjectMobileImage}`}
                                alt={`프로젝트 모바일 이미지 ${index + 1}`}
                                onError={handleImgError}
                                loading="lazy"
                                className='mobileImg'
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <button className="custom-prev-button" aria-label="이전 프로젝트">
                <span>&#10094;</span>
            </button>
            <button className="custom-next-button" aria-label="다음 프로젝트">
                <span>&#10095;</span>
            </button>
            </div>
            <div className="curriculum-container">
                <button 
                    className="curriculum-download-btn"
                    onClick={() => handleProjectClick(project[activeSlideIndex]?.courseProjectLink)}
                    aria-label="프로젝트 보러가기"
                >
                    보러 가기 +
                </button>
            </div>
            </>
    ), [project, handleImgError, handleProjectClick, activeSlideIndex]);

    return (
        <div className="category-section-container">
             {/* 헤더 섹션 */}
            <div className="sticky-header">
                <RegisterSection outline={outline} courseType={courseType} />
                <CategoryBar 
                    onScrollToImage={scrollToImage} 
                    scrollToProject={scrollToProject} 
                    content={content} 
                    isProjectPublic={isProjectPublic} 
                />
            </div>
            {/* 프로젝트 섹션 */}
            {isProjectPublic && project.length > 0 && (
            <div ref={projectSectionRef} className="project-section">
                {/* 웹용 이미지 */}
                <img 
                    src={`/uploads/${outline.courseProjectSecotorImage}`} 
                    alt="프로젝트 설명 이미지" 
                    className="project-web-img"
                    onError={handleImgError}
                />
                <div className="swiper-container">
                    {renderProjects()}
                </div>
            </div>
            )}

            {/* 컨텐츠 섹션 */}
            <div className="category-section-content">
                {renderContent()}
            </div>
           
            {/* TOP 버튼 */}
            {showTopButton && (
                <button 
                    className="top-button"
                    onClick={scrollToTop}
                    aria-label="페이지 상단으로 이동"
                >
                    TOP
                </button>
            )}
        </div>
    );
});

export default CategorySection;