import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import "user/story/commons/StoryContentsDetail.css";
import "user/commons/Loading.css";
import Banner from 'user/commons/Banner';
import Footer from 'user/footer/Footer';
import Header from 'user/header/Header';
import { call } from 'service/ApiService';
import Loading from 'user/commons/Loading';
import { marked } from 'marked';
import PageLayout from 'user/PageLayout';
import { Viewer } from '@toast-ui/react-editor';

function ContentsDetail() {
  const { id: contentId } = useParams();
  const [searchParams] = useSearchParams();
  const menu = searchParams.get('menu');

  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);  // 에러 상태

  useEffect(() => {
    if (contentId && menu) {
      const apiEndpoint = menu === 'itech' ? `/api/v1/eyetech/${contentId}` :
      menu === 'blog' ? `/api/v1/blog/${contentId}` : 
      `/api/v1/video-story/${contentId}`;

      setIsLoading(true);
      call(apiEndpoint, 'GET')
        .then((response) => {
          setContent(response);
          // console.log("상세 조회 성공:", response);
        })
        .catch((error) => {
          // console.log('스토리 상세조회에 실패했습니다.', error);
          setIsError(true);
        })
        .finally(() => setIsLoading(false));
    }
  }, [contentId, menu]);

  // 제목에 따른 웹 페이지 타이틀 바꾸기 ([]로 content에 값이 변경되거나, ifn문으로 content가 값이 있어야만 실행함)
  useEffect(() => {
    if (content && (content.eyetechTitle || content.blogTitle || content.videoStoryTitle)) {
      document.title = `${content.eyetechTitle || content.blogTitle || content.videoStoryTitle} | 신한DS 금융SW 아카데미`;
    }
  }, [content]);


  if (isLoading) {
    return (
      <Loading/>
    );
  }

  if (!content) {
    return <div>해당 콘텐츠를 찾을 수 없습니다.</div>
  }

  const formatDate = (dateString) => {
    if (!dateString) return '날짜 정보 없음';
    return dateString.split('T')[0];
  };

  marked.setOptions({
    breaks: true,
  });

  return (
    <div>
      <PageLayout setIsError={setIsError}>
        <Banner category="story"/>
        <div className='contents-detail-container'>
          <div className='contents-detail-list-container'>
            <span className='contents-detail-menu'>
              {menu === 'itech' ? '아이테크' : menu === 'blog' ? '블로그' : '영상 스토리'}
            </span>
            <br></br>
            <span className='contents-detail-category'>
              {content.eyetechCategoryName || content.blogCategoryName || content.videoStoryCategoryName}
            </span>
            <span className='contents-detail-title'>
              {content.eyetechTitle || content.blogTitle || content.videoStoryTitle}
            </span>
            <p className='contents-detail-date'>
              {formatDate(content.eyetechCreateTime || content.blogCreateDate || content.videoStoryCreateDate)}
            </p>
            <hr />
            <div className='contents-detail-media'>
              <div className='video-player-container'>
                {content.videoStoryYoutubeId && (
                  <iframe
                    src={`https://www.youtube.com/embed/${content.videoStoryYoutubeId}`}
                    title="YouTube video player"
                  ></iframe>
                )}
              </div>
              <div className='contents-detail-content'>
                <Viewer
                  initialValue={content.eyetechContent || content.blogContent || content.videoStoryContent} />
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    </div>
  );
}

export default ContentsDetail;